/* @import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css"; */


@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.css";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeicons/primeicons.css";
/* @import "../node_modules/primeng/resources/themes/bootstrap4-light-blue/theme.css"; */
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
 /*  min-height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center; */
}
.main-content{
  min-height: 100vh;
  display: grid;
  align-items: start;
  justify-content: center;
}

html, body {
  overflow-x: hidden;
}
 
.custom-card {
  width: 100% !important;
}

div[ng-reflect-ng-style] *:not([class^="text-"]) {

  font-size: inherit !important;
}

.searchglobally {
  width: 100%;
}


div {
  /* Add the styles you want to apply to all div elements here */
  /* For example: */
  /* font-family: Arial, sans-serif;
  color: #333; */
  /* padding: 0.5rem; */
}

:host {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
/* 
.p-card .p-card-body {
    padding: 0rem;  
}

.p-tabview .p-tabview-panels {
  padding: 0rem;  
}


@media screen and (max-width: 575px) {
  body {
    padding: 0.5em;
  }
  
  .p-card .p-card-body {
    padding: 1rem;
  }
  
  .p-tabview .p-tabview-panels {
    padding: 0.75rem;
  }

  .searchglobally {
    width: 75%;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  body {
    padding: 0.75em;
  }

  .p-card .p-card-body {
    padding: 1.25rem;
  }

  .p-tabview .p-tabview-panels {
    padding: 1rem;
  }

  .searchglobally {
    width: 75%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  body {
    padding: 0.875em;
  }
  
  .searchglobally {
    width: 75%;
  }
}

@media screen and (min-width: 992px) {
  body {
    padding: 1em;
  }

  .p-card .p-card-body {
    padding: 1.5rem;
  }

  .p-tabview .p-tabview-panels {
    padding: 1.25rem;
  }

  .searchglobally {
    width: 100%;
  }
} */


/* Extra Small */
body {
  /* padding: 0.5em; */
}

.searchglobally {
  width: 90%;
}

.p-card .p-card-body {
  padding: 0.75rem;
}

.p-tabview .p-tabview-panels {
  padding: 0.5rem;
}

/* Small */
@media screen and (min-width: 576px) {
  body {
    /* padding: 0.75em; */
  }

  .searchglobally {
    width: 92%;
  }
  
  .p-card .p-card-body {
    padding: 1rem;
  }

  .p-tabview .p-tabview-panels {
    padding: 0.75rem;
  }
}

/* Medium */
@media screen and (min-width: 768px) {
  body {
    /* padding: 1em; */
  }

  .searchglobally {
    width: 94%;
  }

  .p-card .p-card-body {
    padding: 1.25rem;
  }

  .p-tabview .p-tabview-panels {
    padding: 1rem;
  }
}

/* Large */
@media screen and (min-width: 992px) {
  body {
    /* padding: 1em; */
  }

  .searchglobally {
    width: 96%;
  }

  .p-card .p-card-body {
    padding: 1.5rem;
  }

  .p-tabview .p-tabview-panels {
    padding: 1.25rem;
  }
}

/* Extra Large */
@media screen and (min-width: 1200px) {
  body {
    /* padding: 1.5em; */
  }
 
  .searchglobally {
    width: 100%;
  }

  .p-card .p-card-body {
    padding: 1.75rem;
  }

  .p-tabview .p-tabview-panels {
    padding: 1.5rem;
  }
}
